import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
export default class AboutTwo extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className="about-three">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="about-two__content">
                <p className="about-two__text">
                  Don’t we all wish to see our little ones master the art of
                  learning by channelling their grasping powers, harnessing
                  their inquisitiveness and developing keenness to learn, as
                  they take baby-steps towards formal education? Excellent early
                  childhood education programmes will help them achieve that!
                  The formative years of your little ones is when they develop
                  gross motor skills, fine motor skills, language and social
                  skills, among many other life-altering capabilities. Moulding
                  your child’s development process in these years is absolutely
                  vital to help them become the super-achievers that they will
                  be in the future!
                  <br></br>
                  <br></br>
                </p>
              </div>
            </div>
            <div className="col-xl-8">
              <p className="about-two__text">
                Kids World Education Consultants has been transforming the
                landscape in the field of early childhood education since 2012.
                We envision to dream beyond the ordinary and achieve our mission
                of establishing a fun-filled educational environment for a
                better tomorrow, the stepping stone to the world of future
                accomplishments of your little ones! With collective experience
                of nearly 25 years, Kids World Education Consultants has
                excelled in implementing pre-primary education programmes
                (play-school and kindergarten), spoken English for children,
                parent counseling and educators’ training, in the arena of early
                childhood education. Our expertise has helped us develop
                harmonious associations with schools all over India and across
                the borders, in Bhutan and Azerbaijan.
                <br></br>
                <br></br>
                We offer a host of innovative, age-appropriate, theme-based
                products and services to engage with our young learners. Some of
                the significant ones are,
              </p>
              <div className="about-two__single-wrap">
                <div className="about-two__single">
                  <div className="about-two__single-icon">
                    <i className="kipso-icon-professor"></i>
                  </div>
                  <div className="about-two__single-content">
                    <p className="about-two__single-text">For young learners</p>
                  </div>
                </div>
                <div className="about-two__single">
                  <div className="about-two__single-icon">
                    <i className="kipso-icon-knowledge"></i>
                  </div>
                  <div className="about-two__single-content">
                    <p className="about-two__single-text">
                      For parents & teachers
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="sidebar__single sidebar__category">
                <h3 className="sidebar__title">Services for young learners</h3>
                <ul className="sidebar__category-list">
                  <li className="sidebar__category-list-item">
                    <a href="#none">Jolly Phonics & Grammar</a>
                  </li>
                  <li className="sidebar__category-list-item">
                    <a href="#none">Handwriting Without Tears programme</a>
                  </li>
                  <li className="sidebar__category-list-item">
                    <a href="#none">E-learning modules for kids</a>
                  </li>
                </ul>
              </div>
              <div className="sidebar__single sidebar__category">
                <h3 className="sidebar__title">Services for parents</h3>
                <ul className="sidebar__category-list">
                  <li className="sidebar__category-list-item">
                    <a href="#none">
                      Pre-school setup and curriculum development
                    </a>
                  </li>
                  <li className="sidebar__category-list-item">
                    <a href="#none">
                      Jolly Phonics & Grammar (for teachers and parents)
                    </a>
                  </li>
                  <li className="sidebar__category-list-item">
                    <a href="#none">
                      Professional development courses for teachers
                    </a>
                  </li>
                  <li className="sidebar__category-list-item">
                    <a href="#none">E-learning modules for educators</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
