import React from "react";
import team1 from "../../assets/images/profile/neeti.png";
import team2 from "../../assets/images/profile/anagha.png";
import team3 from "../../assets/images/profile/hiral.png";
import team4 from "../../assets/images/profile/ruchi.png";
import team5 from "../../assets/images/profile/package.jpeg";
import team6 from "../../assets/images/profile/jinal.jpg";
const AboutTeam = () => {
  return (
    <section className="team-one team-one__become-teacher">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Meet the TEAM <br />
          </h2>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
                <a href="/teachers/neeti"><img src={team1} alt="" /></a>
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <a href="/teachers/neeti">Neeti Nagarkar</a>
                </h2>
                <p className="team-one__designation">Founder & Teacher Trainer</p>

              </div>
             
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
              <a href="/teachers/anagha"> <img src={team2} alt="" /></a>
               
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <a href="/teachers/anagha">Anagha Bahulikar</a>
                </h2>
                <p className="team-one__designation">Founder & Teacher Trainer</p>

              </div>
             
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
              <a href="/teachers/hiral">  <img src={team3} alt="" /></a>
              
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <a href="/teachers/hiral">Hiral Shah</a>
                </h2>
                <p className="team-one__designation">Early Years Educator</p>

              </div>
             
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
              <a href="/teachers/ruchi"> <img src={team4} alt="" /></a>
               
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <a href="/teachers/ruchi">Ruchi Shah</a>
                </h2>
                <p className="team-one__designation">Early Years Educator</p>

              </div>
             
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
              <a href="/teachers/nikita"> <img src={team5} alt="" width={200}/></a>
               
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <a href="/teachers/nikita">Nikita Kashyap</a>
                </h2>
                <p className="team-one__designation">Early Years Educator & Jolly Phonics Teacher</p>

              </div>
             
            </div>
          </div>
          <div className="col-xl-4  col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
              <a href="/teachers/jinal"> <img src={team6} width={200} alt="" /></a>
               
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <a href="/teachers/ruchi">Jinal Maniar</a>
                </h2>
                <p className="team-one__designation">Early Years Educator</p>

              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutTeam;
