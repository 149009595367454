import React from "react";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import PageHeader from "../components/PageHeader";
import AboutUs from "../components/About/AboutUs";
import Footer from "../components/Footer";
import AboutTeam from "../components/About/AboutTeam";

const AboutPage = () => {
  return (
    <Layout pageTitle="KWEC | About">
      <Navbar />
      <PageHeader title="About" />
      <AboutUs />
      <AboutTeam />
      <Footer />
    </Layout>
  );
};

export default AboutPage;
